import React, { useState, useEffect } from 'react';
import Navbar from "../nav/Navbar"
import working from '../images/thinker.webp'
import './Home.css'
import LoadingScreen from '../loadingScreen/Loading';


const Home = () =>{
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate fetching data with setTimeout
        // Replace this with your actual data fetching logic
        setTimeout(() => {
            setIsLoading(false);
        }, 1500); // Assume it takes 3 seconds to load data
    }, []);

    if (isLoading) {
        return <LoadingScreen />;
    }

    var content 

    content = (
        <div className="homeContainer">
            <Navbar />
            <div className="home-section-grids">
                <div className="home-left-grid">
                    <div className="welcome_message_container">
                        <h1 className="home-title">Hello World!</h1>
                        
                        <p>Welcome to the crossroads of creativity and logic – the digital home of Edwardt S. Baum. Delighted you've navigated here!</p>

                        <p>In the words of Ralph Waldo Emerson, "It's not the destination, it's the journey." So, consider this portfolio a map – not of where I've arrived, but the diverse paths I've explored and the terrains I've traversed in the realm of innovation and design.</p>

                        <p>Dive in, and let's embark on a new voyage together. Who knows? Our collaboration might just be the next great adventure.</p>
                        
                    </div>
                </div>
                <div className="home-top-grid">
                    <h1 className="home-title-mobile-1">Hello World !</h1>
                </div>
                <div className="home-right-grid">
                    <img src={working} alt="Edwardt is studying" className="working"></img>
                </div>
            </div>
        </div>
    )

    return content

}

export default Home