import React, { useState, useEffect } from 'react';
import Navbar from "../nav/Navbar"
import artist from '../images/artist.webp'
import LoadingScreen from '../loadingScreen/Loading';
import './About.css'


const About = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate fetching data with setTimeout
        // Replace this with your actual data fetching logic
        setTimeout(() => {
            setIsLoading(false);
        }, 1500); // Assume it takes 3 seconds to load data
    }, []);

    if (isLoading) {
        return <LoadingScreen />;
    }

    var content

    content = (
        <div className="aboutContainer">
            <Navbar />
            <div className="about-section-grids">
                <div className="about-top-grid">
                    <h1 className="about-title-mobile">About Me</h1>
                </div>
                <div className="about-left-grid">
                    <div className="about_message_container">
                        <h1 className="about_message">About Me</h1>
                        <p>In my pursuit of technological mastery, I've honed my skills across the spectrum of web development, from the robust back-end frameworks of Django, Flask, FastAPI, to the dynamic, asynchronous environments of Node.js. On the front-end, React.js and Next.js serve as my primary canvases, allowing me to craft immersive user experiences that captivate and engage.</p>
                        <p>My journey extends into the cloud, where AWS, Azure, and GCP have provided vast landscapes for my projects, each platform offering unique tools and services to expand my digital horizons.</p>
                        <p>Beyond the cloud, my ventures into network engineering have seen me leverage the precision of UDP and TCP protocols to ensure optimal data flow across ruggedized routers. This technical prowess also fuels my creative endeavors, from animating interactive stories to laying the groundwork for video game creation using CSS, HTML, and JavaScript.</p>
                        <p>Innovation is the core of my being. Whether it's forging knives, cultivating greenhouses, piloting FPV drones, assembling custom keyboards, or developing chatbots, each project is a testament to my creative spirit and technical acumen.</p>
                        <p>My foundational experiences as an Infantry Marine have imbued me with unparalleled discipline and a boldness to explore new frontiers. These qualities not only drive my technical projects but also shape my approach to problem-solving and innovation.</p>
                        <p>I am Edwardt Baum—where artistry meets engineering. My journey is an ongoing exploration of the potential that lies at the intersection of creativity and technology.</p>
                        <p>If my path sparks an interest or if you're curious about the possibilities of collaboration, please reach out. Let's navigate the future of innovation, together.</p>
                        
                    </div>
                </div>
                <div className="about-right-grid">
                    <img src={artist} alt="Edwardt is painting" className="working"></img>
                </div>
            </div>
        </div>
    )

    return content
}

export default About

