import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // For React Router v6
import './Navbar.css';
import logo from '../images/Logo Red.png';
import arrowImage from '../images/DownArrow.gif'; // Ensure you have the correct path

const Nav = () => {
    const navigate = useNavigate(); // For React Router v6
    const location = useLocation();
    //const currentPath = location.pathname;
    const paths = ["/", "/about", "/projects", "/contact"]; // Define navigation order
    //const isVerticalNav = location.pathname === '/projects' || location.pathname === '/contact';
    const [isVerticalNav, setIsVerticalNav] = useState(false);
    const currentPathIndex = paths.findIndex(path => path === location.pathname);
    const nextPath = paths[(currentPathIndex + 1) % paths.length]; // Determines the next path
    useEffect(() => {
        console.log("Current Path:", location.pathname);
        console.log("Is Vertical Nav:", isVerticalNav);
    }, [location.pathname, isVerticalNav]);
    useEffect(() => {
        setIsVerticalNav(location.pathname === '/projects' || location.pathname === '/contact');
    }, [location.pathname]);
    const arrowButtons = paths.map((path, index) => {
        // Determine z-index: higher for the next path, lower for others
        const zIndex = (index === (currentPathIndex + 1) % paths.length) ? 4 : 1;
        return (
            <img
                key={path}
                src={arrowImage}
                alt={`Navigate to ${path}`}
                className="fixed-arrow"
                style={{ zIndex }}
                onClick={() => navigate(nextPath)}
            />
        );
    });

    //opening the hamburger menu and 
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }
    return (
        <nav className={`navbar ${isVerticalNav ? 'vertical-nav' : ''}`}>
            <div className='logo-container'>
                <div className='nav-sphere'>
                    <img src={logo} alt="Logo" className="logo" />
                </div>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {isOpen &&(
                <div className="mobile-menu">
                    <NavLink to="/" className={({ isActive }) => isActive ? "nav-button-mobile active-link" : "nav-button-mobile"}>Home</NavLink>
                    <NavLink to="/about" className={({ isActive }) => isActive ? "nav-button-mobile active-link" : "nav-button-mobile"}>About</NavLink>
                    <NavLink to="/projects" className={({ isActive }) => isActive ? "nav-button-mobile active-link" : "nav-button-mobile"}>Projects</NavLink>
                    <NavLink to="/contact" className={({ isActive }) => isActive ? "nav-button-mobile active-link" : "nav-button-mobile"}>Contact</NavLink>
                </div>
            )}
            <div className='buttons-container'>
                <NavLink to="/" className={({ isActive }) => isActive ? "nav-button active-link" : "nav-button"}>Home</NavLink>
                <NavLink to="/about" className={({ isActive }) => isActive ? "nav-button active-link" : "nav-button"}>About</NavLink>
                <NavLink to="/projects" className={({ isActive }) => isActive ? "nav-button active-link" : "nav-button"}>Projects</NavLink>
                <NavLink to="/contact" className={({ isActive }) => isActive ? "nav-button active-link" : "nav-button"}>Contact</NavLink>
            </div>
            {/* Arrow button with dynamic link */}
            <div className="arrow-container">{arrowButtons}</div>
            
        </nav>
    );
};

export default Nav;
