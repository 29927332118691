import Navbar from "../nav/Navbar"
import React, { useState } from "react";
import './Contact.css'

const Contact = () => {
    var content 

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Define the URL of your backend endpoint
        //const url = 'http://localhost:8000/contacts/';
        const url = 'https://edwardtsbaum-backend.onrender.com/contacts/';
        
        try {
            const response = await fetch(url, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (response.ok) {
                const result = await response.json();
                console.log(result);
                alert("Form submitted. Thank you!");
                // Reset form after submission
                setFormData({
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                    message: ''
                });
            } else {
                // Handle server errors or invalid responses
                alert("An error occurred. Please try again.");
            }
        } catch (error) {
            console.error('Error:', error);
            alert("An error occurred. Please try again.");
        }
    };


    content = (
        <div className="contactContainer">
            <Navbar />
            <div className="contact-form">
                <div className="form">
                    <h2>Contact Form</h2>
                    <p>Excited about the possibility of working together? Just fill out this quick form, and I'll get back to you soon!</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="firstname">First Name</label>
                                <input type="text" id="firstname" name="firstname" value={formData.firstName} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Last Name</label>
                                <input type="text" id="lastname" name="lastname" value={formData.lastName} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="phone">
                            <label htmlFor="phone">Phone Number:</label>
                            <input type="tel" id="phone" name="phone" value={formData.phoneNumber} onChange={handleChange} />
                        </div>
                        <div className="email">
                            <label htmlFor="email">Email Address:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="message-contianer">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" className="message-input" value={formData.message} onChange={handleChange} required />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )

    return content

}

export default Contact