// LoadingScreen.js
import React from 'react';
import './Loading.css'; // Assuming you have a CSS file for styles

const LoadingScreen = () => (
    <div className="loading-screen">
        {/* You can customize this with your own loading animation or image */}
    </div>
);

export default LoadingScreen;
