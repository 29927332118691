import React, {useState, useEffect} from "react";
import Navbar from "../nav/Navbar"
import stocks from '../images/icons/stocks.webp'
import GPS from '../images/icons/GPS.webp'
import usmc from '../images/icons/usmc.webp'
import analytics from '../images/icons/analytics.webp'
import control from '../images/icons/control.webp'
import drone from '../images/icons/drone.webp'
import marine from '../images/marine.webp'
import manager from '../images/manager.webp'
import gpsfinder from '../images/gps-finder.webp'
import stockTrader from '../images/stock-trader.webp'
import fpv from '../images/fpv.webp'
import working from '../images/hard-at-work-2.webp'
import './Projects.css'
import LoadingScreen from '../loadingScreen/Loading';


const Projects = () => {

    const projects = [
        { id: 1, title: "Content Control System", description: [
            "Developed a system for Ballyhoo to broadcast media on large boats in South Miami's waterways, addressing challenges in scheduling and on-demand content control using cloud-connected Brightsign media players.",
            "Utilized a Model View Controller (MVC) architecture with a full JavaScript stack, including Node.js (backend) and React (frontend), paired with an IBR900 Series Router and a Brightsign XT1144 media player for hardware.",
            "Enabled dynamic media content control through UDP messages sent from a cloud server to routers on boats, facilitating real-time updates and management.",
            "Incorporated advanced programming techniques, data structures, and a suite of technologies such as EC2 on AWS for server hosting, Nginx for reverse proxy services, and Express for efficient backend operations."
        ], image: control, contentImage: working},
        { id: 2, title: "Autonomous Drone Project", description: [
            "Established a Linux-based build environment to leverage Arducopter software, enabling advanced autopilot functionalities within DroneKit applications.",
            "Implemented Python scripts to interface with the DroneKit API, enabling command transmissions to the drone's flight control board via a Mavlink terminal.",
            "Employed a 'software in the loop' testing strategy, simulating drone operations within a virtual environment to rigorously test Python-based drone commands."
        ], image: drone, contentImage: fpv},
        { id: 3, title: "Stock Market Project", description: [
            "Experience coming soon.",
        ], image: stocks, contentImage: stockTrader},
        { id: 4, title: "GPS Content Change", description: [
            "Developed a robust UDP client using Node.js's dgram module, effectively configuring host and port settings to enable real-time data transmission to boats.", 
            "Engineered a system to fetch critical geolocation data (latitude, longitude, heading, speed, and status) for maritime navigation, utilizing custom headers for secure API requests.",
            "Implemented sophisticated algorithms to calculate geofences for maritime stops, leveraging mathematical models to determine a boat's presence within designated navigational areas.",
            "Designed a conditional messaging system that dynamically sends UDP packets based on a boat's geolocation, facilitating automated responses when entering or exiting geofences.",
            "Created a flexible function to manage the periodic execution of the geolocation and geofence checking process, allowing for on-demand activation or deactivation to adapt to real-time monitoring needs.",
        ], image: GPS, contentImage: gpsfinder},
        { id: 5, title: "United States Marine Corps", description: [
            "Monitored the security and accountability of over $1 Million of highly sensitive military and communications equipment inside the security position resulting in zero losses.",
            "Facilitated a training program that would instruct new Marines on tactics and reporting procedures resulting in the combat readiness of more than 30 junior Marines.",
            "Supervised a team of four Marines while providing security for more than $1 Billion worth of serialized military aircrafts ensuring mission accomplishment."
        ], image: usmc, contentImage: marine},
        { id: 6, title: "Data Analytics", description: [
            "Spearheaded the development of a robust application that automates the tracking of data inputs from monday.com, eliminating the need for manual reporting and significantly enhancing operational efficiency.",
            "Engineered a solution to automate the generation of critical reports and charts, such as Automated Loop Structure Spot Reports and Share of Voice Inventory Level Reports, enabling real-time decision-making with the push of a button.",
            "Implemented sophisticated algorithms to track revenue accrued over the campaign's lifetime and forecast potential inventory play counts per client, providing actionable insights for strategic planning.",
            "Designed and integrated advanced analytics features to calculate billing date to start date averages, duration between sales stages, and monthly revenue metrics, facilitating a deeper understanding of sales cycles and revenue streams.",
            "Created year-over-year inventory level and revenue analysis charts, along with fleet deployment analysis tools, to offer comprehensive insights into performance trends and operational effectiveness."
        ], image: analytics, contentImage: manager},
        // Add more projects as needed
      ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate fetching data with setTimeout
        // Replace this with your actual data fetching logic
        setTimeout(() => {
            setIsLoading(false);
        }, 1500); // Assume it takes 3 seconds to load data
    }, []);

    if (isLoading) {
        return <LoadingScreen />;
    }

    var content

    content = (
        <div className="projectsContainer">
            <Navbar />
            <div className="portfolio-grids">
                <div className="portfolio-instructions">
                    <h2>Portfolio Instructions</h2>
                    <p>Click on each icon within the circle to see projects</p>
                </div>
                <div className="projects_container">
                    <div className="sphere-container">
                        {projects.map((project, index) => (
                            <div key={index} className="sphere" onClick={() => {
                                setIsModalOpen(true);
                                setSelectedProject(project);
                            }}>
                                <img src={project.image} alt={`Project ${project.id}`} className="sphere-image" />
                            </div>
                        ))}
                    </div>
                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <div className="project-info">
                                    <h2>{selectedProject?.title}</h2>
                                    <ul>
                                        {selectedProject?.description.map((point, index) => (
                                            <li key={index}>{point}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="project-image">
                                    <img src={selectedProject?.contentImage} alt={selectedProject?.title} />
                                </div>
                                <button className="close-button" onClick={() => setIsModalOpen(false)}>
                                    &#8592; Back
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
        return content
}

export default Projects