import {Routes, Route} from 'react-router-dom'
import Home from './components/home/Home'
import About from './components/about/About'
import Projects from './components/projects/Projects'
import Contact from './components/contact/Contact'
import React, { useState, useEffect } from 'react';
import LoadingScreen from './components/loadingScreen/Loading';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      // Simulate fetching data with setTimeout
      // Replace this with your actual data fetching logic
      setTimeout(() => {
          setIsLoading(false);
      }, 3000); // Assume it takes 3 seconds to load data
  }, []);

  if (isLoading) {
      return <LoadingScreen />;
  }

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/projects' element={<Projects />}/>
        <Route path='/contact' element={<Contact />}/>
      </Routes>
    </div>
    
  );
}

export default App;
